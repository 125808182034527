import React, { useState, useEffect, useRef, useImperativeHandle } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useParams } from 'react-router-dom'

import SmoothYoutube from '../SmoothYoutube';
import CustomSoundcloud from '../CustomSoundcloud';

import { reel } from './demo-reel-data';
import { playOneShot } from '../scripts/audio-handler';


function X({ ...args }) {
  return (
    <motion.div className="nav-btn x" {...args} onMouseEnter={() => playOneShot("fadeLow")} onMouseLeave={() => playOneShot("fadeLowRev")}>
      x
    </motion.div>
  );
}


function DetailsPane({ close }, ref) {
  const vidRef = useRef();
  useImperativeHandle(ref, () => ({
    pause: () => {
      vidRef.current?.pause();
    }
  }));
  const [loaded, setLoaded] = useState(false);
  let { pieceId } = useParams();
  const release = reel[pieceId];

  useEffect(() => {
    setLoaded(false);
  }, [pieceId]);

  return (
    <AnimatePresence> {
      release != null &&
      <motion.div
        key="container"
        className="release-detail-pane"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <X key="x" onClick={close} />

        <AnimatePresence exitBeforeEnter>
          <motion.div
            key={pieceId}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <div>
              <div className="release-details">
                <div className="release-text">
                  <h3>
                    {release.name}
                  </h3>
                  <p dangerouslySetInnerHTML={{ __html: release.description }} />
                </div>
              </div>

              {release.link.type === "youtube" ?
                <div className="release-yt">
                  <SmoothYoutube loaded={loaded} ref={vidRef} onLoad={setLoaded} videoId={release.link.link} />
                </div>
                :
                <div className="release-yt">
                  <div className="vid-parent-container">
                    <div>
                      <CustomSoundcloud ref={vidRef} link={release.link} />
                    </div>
                  </div>
                </div>
              }
            </div>

          </motion.div>
        </AnimatePresence>
      </motion.div>
    }
    </AnimatePresence>
  )
}

DetailsPane = React.forwardRef(DetailsPane);

export default DetailsPane
