import React, { Component } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';

export default class ColoredScrollbars extends Component {

  constructor(props, ...rest) {
    super(props, ...rest);
    this.state = { top: 0 };
    this.handleUpdate = this.handleUpdate.bind(this);
    this.renderThumb = this.renderThumb.bind(this);
  }

  handleUpdate(values) {
    const { top } = values;
    this.setState({ top });
  }

  renderThumb({ style, ...props }) {
    const thumbStyle = {
      backgroundColor: ` rgba(231, 236, 244, 0.2)`,
      borderRadius: '24px'
    };
    return (
      <div
        style={{ ...style, ...thumbStyle }}
        {...props} />
    );
  }

  render() {
    return (
      <Scrollbars
        autoHide
        renderThumbHorizontal={this.renderThumb}
        renderThumbVertical={this.renderThumb}
        onUpdate={this.handleUpdate}
        {...this.props} />
    );
  }
}