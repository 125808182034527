import React from 'react'

function Logo() {
  return (
    <div>
      <svg id="font-logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 456.09 44.43">
        <title>bluedrak3 logo</title>
        <g>
          <g>
            <path
              d="M0,.38C7.19.35,14.39.31,21.58.3c5.41,0,10.83,0,16.24,0,1.21,0,2.42,0,3.62.1,7,.59,10.42,4.34,10.34,11.36,0,1.08,0,2.17-.06,3.24-.24,3.18-1.09,6-4.42,7.72,2.81,1.61,4.41,3.92,4.39,7a38.75,38.75,0,0,1-.78,8.48C50.26,41,48,42.65,45.27,43.39a25,25,0,0,1-6.19.91c-12.36.08-24.71,0-37.06,0A11.81,11.81,0,0,1,0,44V18.74c3.94,0,7.89-.09,11.83-.1q13.56,0,27.12,0c2.17,0,4.07-.43,5-2.68,2-4.64-.47-8.42-5.44-8.43q-18.13,0-36.28,0A19.11,19.11,0,0,1,0,7.27ZM7.53,37.06c11.09,0,22,0,32.84-.08a4.46,4.46,0,0,0,3.12-1.83,6.78,6.78,0,0,0,.24-6.93c-1-2-2.92-2.23-4.92-2.23C29,26,19.19,26,9.38,26c-.61,0-1.21.05-1.85.09Z" />
            <path
              d="M58.15,0l2.06.46V28.3C60.21,34.39,62.73,37,69,37c11,.07,21.93,0,32.9,0h2.53v7.29h-1.89c-12.3,0-24.61,0-36.91,0A22.52,22.52,0,0,1,61,43.89c-5-1-7.77-4.2-8-9.33-.18-4.65-.12-9.3-.14-14q0-9.27,0-18.55a18,18,0,0,1,.27-2Z" />
            <path
              d="M350.11.44h7.3V14.8c.92-.43,1.53-.69,2.12-1,8.29-4.23,16.56-8.49,24.87-12.67a7.41,7.41,0,0,1,3.08-.73,117.9,117.9,0,0,1,13,.36L365.55,18.62c1.06.08,1.58.15,2.1.15,7,0,14-.05,21,0a26.17,26.17,0,0,1,6,.77c3.89,1,6.51,3.62,6.76,7.62.34,5.63.08,11.3.08,17.07h-7.16V37.72c0-2.3.07-4.6,0-6.89-.12-2.87-1.61-4.65-4.29-4.67-10.71-.08-21.41,0-32.12,0-.11,0-.22.1-.51.26v18h-5.68q-28,0-56,0a7.86,7.86,0,0,1-3.43-.84C281.47,38,270.68,32.36,259.84,26.83a2.3,2.3,0,0,1-1.51-2.5c.11-1.76,0-3.54,0-5.59h38.35c4.62,0,6.45-2.11,5.9-6.73-.34-2.88-1.88-4.32-4.81-4.36-3.63,0-7.27,0-10.9,0H258.51V.53A14.09,14.09,0,0,1,260,.38c12.62,0,25.24,0,37.86,0a20.68,20.68,0,0,1,5.63.89,8.29,8.29,0,0,1,6.29,7.41,40.67,40.67,0,0,1-.05,9c-.51,4.31-3.24,6.91-7.75,7.84a25.35,25.35,0,0,1-4.93.47c-7,.05-14,0-21,0h-1.67l-.14.38L303,41.13c.37-.66.72-1.23,1-1.82q7.72-15.38,15.45-30.73a39.85,39.85,0,0,1,3.16-5.45c2.61-3.61,6.88-3.6,9.35.11a80.45,80.45,0,0,1,4.51,8.2c4.45,8.8,8.86,17.63,13.6,26.43Zm-8.25,36.62c-.24-.58-.37-.93-.53-1.26-4-8.08-8.06-16.17-12.14-24.22a12.54,12.54,0,0,0-1.91-2.24,14.31,14.31,0,0,0-1.87,2.29c-4.14,8-8.22,16.09-12.31,24.14a11.62,11.62,0,0,0-.47,1.29Z" />
            <path
              d="M156.16,7.62V.39c.74,0,1.42-.09,2.1-.09,24,0,48.07-.07,72.1.08a49.71,49.71,0,0,1,12.26,1.56c7.51,2,12,7.06,13.46,14.78a30.25,30.25,0,0,1-.27,12.71C254,36.89,249.1,41.3,241.87,43.12a39.61,39.61,0,0,1-9.45,1.21c-24.92.09-49.84,0-74.77,0-.44,0-.87,0-1.45-.09V37.11h2.38c24,0,47.94.07,71.9,0a37,37,0,0,0,10.14-1.51c5-1.48,7.92-5.21,8.48-10.47a23.62,23.62,0,0,0-.17-6.45c-1-5.7-4.83-8.86-10.22-10.06A40.36,40.36,0,0,0,230,7.69c-23.71-.08-47.43-.06-71.14-.07Z" />
            <path
              d="M404.31,7.63V.37h1.89c12.23,0,24.47,0,36.7,0A25.15,25.15,0,0,1,448,.94c4.39.94,7.18,3.65,7.66,8.15a35.32,35.32,0,0,1-.24,8.15,6.46,6.46,0,0,1-4,5.46,13.78,13.78,0,0,1,3.13,16.61c-2.24,4-6.12,5-10.29,5-12.87.08-25.74,0-38.61,0-.38,0-.75,0-1.26-.07V37.16h2.23q18.06,0,36.13,0c4.95,0,7.5-4.57,5-8.92-1-1.79-2.73-2.15-4.6-2.15L406.84,26H404.4V18.74h2.26q18.16,0,36.33,0c3.89,0,5.57-1.81,5.5-5.68s-1.71-5.41-5.56-5.42H404.31Z" />
            <path
              d="M103,.39h7.11V2.56q0,9.46,0,18.93c0,8.87,3.8,13.51,12.49,15.06a32.08,32.08,0,0,0,14.77-.66c6-1.78,9.45-6,9.62-12.29.18-6.56,0-13.13,0-19.7V.37h7.34V3.08c0,6.44,0,12.88,0,19.32-.08,12.4-6,19.37-18.18,21.38a45.13,45.13,0,0,1-18-.55c-9.08-2.22-14.52-8.08-15.06-17.42C102.58,17.43,103,9,103,.39Z" />
            <path d="M156.13,25.92V18.84h56.09v7.08Z" />
          </g>
        </g>
      </svg>
    </div>
  )
}

export default Logo
