import React, { useState, useEffect, useRef, useImperativeHandle } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useParams } from 'react-router-dom'

import SmoothYoutube from '../SmoothYoutube';
import Spotify from './Spotify';

import { releases } from './releases-data';
import { playOneShot } from '../scripts/audio-handler';


function X({ ...args }) {
  return (
    <motion.div className="nav-btn x" {...args} onMouseEnter={() => playOneShot("fadeLow")} onMouseLeave={() => playOneShot("fadeLowRev")}>
      x
    </motion.div>
  );
}


function ReleaseDetails({ close }, ref) {
  const vidRef = useRef();
  useImperativeHandle(ref, () => ({
    pause: () => {
      vidRef.current?.pause();
    }
  }));
  const [loaded, setLoaded] = useState(false);
  let { releaseId } = useParams();
  const release = releases[releaseId];

  useEffect(() => {
    setLoaded(false);
  }, [releaseId]);

  return (
    <AnimatePresence> {
      release != null &&
      <motion.div
        key="container"
        className="release-detail-pane"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <X key="x" onClick={close} />

        <AnimatePresence exitBeforeEnter>
          <motion.div
            key={releaseId}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <div>
              <div className="release-details">
                <img src={release.img} alt={release.name + " cover art"} />
                <div className="release-text">
                  <h3>
                    {release.name}
                  </h3>
                  <p>
                    {release.description}
                  </p>
                </div>
              </div>

              <div className="release-yt">
                <SmoothYoutube loaded={loaded} ref={vidRef} onLoad={setLoaded} videoId={release.mainLink} />
              </div>

              {/* <div>
                {release.secondaryLinks.map(link => (
                  link.type === "spotify" && <Spotify key={link.type} link={link.link} loaded={loaded} />
                ))}
              </div> */}
            </div>

          </motion.div>
        </AnimatePresence>
      </motion.div>
    }
    </AnimatePresence>
  )
}

ReleaseDetails = React.forwardRef(ReleaseDetails);

export default ReleaseDetails
