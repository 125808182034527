import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useIsPresent, motion } from 'framer-motion';

import { playOneShot } from './scripts/audio-handler';


function circleVariants(x, y, scale) {
  return {
    initial: {
      x: 0,
      y: 0,
      scale: 1,
      opacity: 0,
      transition: {
        duration: 0
      }
    },
    active: {
      x: x,
      y: y,
      scale: scale,
      opacity: [1, 0],
      transition: {
        duration: 0.3,
        ease: "easeOut"
      }
    }
  }
};

function randNum() {
  return (Math.random() * 100 + 60) * (Math.round(Math.random()) * 2 - 1);
}

function Circle({ isClicked }) {
  return (
    <motion.div
      className="bubble"
      variants={circleVariants(randNum(), randNum(), Math.random() * 3)}
      animate={(() => {
        if (isClicked) return "active";
        else return "initial";
      })()}
      initial="initial"
    />
  );
}

function NavButton({ link, text, children, onClick }) {
  const isPresent = useIsPresent();
  const [expanded, setExpanded] = useState(false);
  const [isClicked, setIsClicked] = useState(false);

  return (
    <Link
      className={expanded ? "expanded nav-btn " : "nav-btn "}
      to={link}
      onClick={() => {
        if (isPresent) {
          onClick?.();
          setIsClicked(true);
          setExpanded(true);
          playOneShot('bleep');
        }
      }}
      onMouseEnter={() => {
        if (!isPresent) return;
        isPresent && playOneShot('fadeLow');
      }}
      onMouseLeave={() => {
        if (!isPresent) return;
        isPresent && playOneShot('fadeLowRev');
      }}
    >
      {text}
      {children}
      <Circle isClicked={isClicked} />
      <Circle isClicked={isClicked} />
      <Circle isClicked={isClicked} />
      <Circle isClicked={isClicked} />
      <Circle isClicked={isClicked} />
      <Circle isClicked={isClicked} />
    </Link>
  )
}

export function DivButton({ text, children, onClick, className }) {
  const [isClicked, setIsClicked] = useState(false);

  return (
    <div
      className={"nav-btn " + className}
      onClick={() => {
        onClick?.();
        setIsClicked(true);
        playOneShot('bleep');
      }}
      onMouseEnter={() => {
        playOneShot('fadeLow');
      }}
      onMouseLeave={() => {
        playOneShot('fadeLowRev');
      }}
    >
      {text}
      {children}
      <Circle isClicked={isClicked} />
      <Circle isClicked={isClicked} />
      <Circle isClicked={isClicked} />
      <Circle isClicked={isClicked} />
      <Circle isClicked={isClicked} />
      <Circle isClicked={isClicked} />
    </div>
  )
}

export default NavButton
