import React from 'react';
import { motion } from 'framer-motion';

import NavButton from './../NavButton';

import Divider from './Divider';
import Logo from './Logo';
import Socials from './Socials';

const variants = {
	initial: {
		opacity: 0,
		y: -10,
		x: 0,
	},
	in: {
		opacity: 1,
		y: 0,
		x: 0,
		transition: {
			duration: 1
		}
	},
	out: {
		opacity: 0,
		transition: {
			delay: 0.2,
			duration: 0.5
		}
	}
};

const spanVariants = {
	in: { opacity: 1 },
	initial: { opacity: 0 },
}

function Title() {
	return (
		<div id="title">
			<div id="font-logo-container">
				<Logo />
			</div>
			<motion.span id="tagline"
				initial="initial"
				animate="in"
				variants={spanVariants}
				transition={{
					delay: 0.5,
					duration: 0.5,
				}}>
				music producer / composer
			</motion.span>
		</div>
	);
}

function Nav() {
	return (
		<nav>
			<ul>
				<li><NavButton link="/releases">music releases</NavButton></li>
				<li><NavButton link="/commissions">commissions</NavButton></li>
				<li><NavButton link="/demo-reel">demo reel</NavButton></li>
			</ul>
		</nav>
	);
}

function TitleCard() {
	return (
		<motion.section
			id="title-card"
			initial="initial"
			animate="in"
			exit="out"
			variants={variants}
		>
			<Title />
			<Divider />
			<Socials />
			<Nav />
		</motion.section>
	)
}

export default TitleCard;
