import React from 'react';
import { playOneShot } from '../scripts/audio-handler';

function SocialIcon({ link, children }) {
  return (
    <a onMouseEnter={() => playOneShot('fadeHigh')} onMouseLeave={() => playOneShot('fadeHighRev')} className="social-icon" href={link}>
      {children}
    </a>
  );
};

function Socials() {
  return (
    <div className="socials">
      <SocialIcon link="https://twitter.com/bluedrak3">
        <svg id="twitter-icon" viewBox="0 0 64 64">
          <path
            d="M48,22.1c-1.2,0.5-2.4,0.9-3.8,1c1.4-0.8,2.4-2.1,2.9-3.6c-1.3,0.8-2.7,1.3-4.2,1.6 C41.7,19.8,40,19,38.2,19c-3.6,0-6.6,2.9-6.6,6.6c0,0.5,0.1,1,0.2,1.5c-5.5-0.3-10.3-2.9-13.5-6.9c-0.6,1-0.9,2.1-0.9,3.3 c0,2.3,1.2,4.3,2.9,5.5c-1.1,0-2.1-0.3-3-0.8c0,0,0,0.1,0,0.1c0,3.2,2.3,5.8,5.3,6.4c-0.6,0.1-1.1,0.2-1.7,0.2c-0.4,0-0.8,0-1.2-0.1 c0.8,2.6,3.3,4.5,6.1,4.6c-2.2,1.8-5.1,2.8-8.2,2.8c-0.5,0-1.1,0-1.6-0.1c2.9,1.9,6.4,2.9,10.1,2.9c12.1,0,18.7-10,18.7-18.7 c0-0.3,0-0.6,0-0.8C46,24.5,47.1,23.4,48,22.1z">
          </path>
        </svg>

      </SocialIcon>
      <SocialIcon link="https://soundcloud.com/bluedrak3">
        <svg id="soundcloud-icon" viewBox="0 0 64 64">
          <path
            d="M43.6,30c-0.6,0-1.2,0.1-1.7,0.3c-0.3-4-3.7-7.1-7.7-7.1c-1,0-2,0.2-2.8,0.5 C31.1,23.9,31,24,31,24.3v13.9c0,0.3,0.2,0.5,0.5,0.5c0,0,12.2,0,12.2,0c2.4,0,4.4-1.9,4.4-4.4C48,31.9,46,30,43.6,30z M27.2,25.1 c-0.7,0-1.2,0.5-1.2,1.1v11.3c0,0.7,0.6,1.2,1.2,1.2c0.7,0,1.2-0.6,1.2-1.2V26.2C28.4,25.6,27.8,25.1,27.2,25.1z M22.2,27.8 c-0.7,0-1.2,0.5-1.2,1.1v8.5c0,0.7,0.6,1.2,1.2,1.2s1.2-0.6,1.2-1.2V29C23.4,28.3,22.9,27.8,22.2,27.8z M17.2,30.2 c-0.7,0-1.2,0.5-1.2,1.1v4.9c0,0.7,0.6,1.2,1.2,1.2c0.7,0,1.2-0.6,1.2-1.2v-4.9C18.5,30.7,17.9,30.2,17.2,30.2z">
          </path>
        </svg>
      </SocialIcon>
      <SocialIcon link="https://youtube.com/c/BlueDrak3">
        <svg id="youtube-icon" viewBox="-256 -256 1024 1024">
          <path
            d="M490.2,113.9c-13.9-24.7-29-29.2-59.6-31C399.9,80.9,322.8,80,256.1,80c-66.9,0-144,0.9-174.7,2.9  c-30.6,1.8-45.7,6.3-59.7,31C7.4,138.6,0,181.1,0,255.9c0,0.1,0,0.1,0,0.1c0,0.1,0,0.1,0,0.1v0.1c0,74.5,7.4,117.3,21.7,141.7  c14,24.7,29.1,29.2,59.7,31.3c30.7,1.8,107.8,2.8,174.7,2.8c66.8,0,143.9-1.1,174.6-2.8c30.7-2.1,45.8-6.6,59.6-31.3  c14.4-24.4,21.7-67.2,21.7-141.7c0,0,0-0.1,0-0.2c0,0,0-0.1,0-0.1C512,181.1,504.7,138.6,490.2,113.9z M192,352V160l160,96L192,352z" />
        </svg>
      </SocialIcon>
      <SocialIcon link="mailto:bluedrak3@gmail.com">
        <svg id="email-icon" viewBox="-256 -256 1024 1024">
          <path
            d="M480,80H32C14.336,80,0,94.336,0,112v18.208l256,108.416l256-108.448V112C512,94.336,497.664,80,480,80z" />
          <path
            d="M262.24,270.72c-1.984,0.864-4.128,1.28-6.24,1.28s-4.256-0.416-6.24-1.28L0,164.96V400c0,17.664,14.336,32,32,32h448    c17.664,0,32-14.336,32-32V164.96L262.24,270.72z" />
        </svg>
      </SocialIcon>
    </div>
  )
}

export default Socials
