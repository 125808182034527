import React from 'react'
import { motion } from 'framer-motion'

function OtherLinks({ clicked }) {
  return (
    <motion.div animate={{ opacity: clicked ? 1 : 0 }} className="otherLinks">
      <a href="mailto: bluedrak3@gmail.com">
        bluedrak3@gmail.com
      </a>
    </motion.div>
  )
}

export default OtherLinks
