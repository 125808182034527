export const reel = {
  traceball: {
    name: "Traceball",
    description: "Music and SFX for a PvP pong fighting game prototype about deflecting and catching an fast ball projectile.",
    type: "Music & SFX",
    img: "/img/reel/traceball.png",
    link: {
      type: "youtube",
      link: "et8OolUiBxQ",
    },
  },
  bwe: {
    name: "Behind Willow's End",
    description: "Music and SFX for a farming sim prototype involving witches and a lot of magic.",
    type: "Music & SFX",
    img: "/img/reel/bwe.png",
    link: {
      type: "youtube",
      link: "RhN2xSXB_Bw",
    },
  },
  text_platformer: {
    name: "Text platformer",
    description: "Unofficial SFX & Music work for a very early WIP game concept made by <a href='https://twitter.com/febucci'>Febucci</a>",
    type: "Music & SFX",
    img: "/img/reel/text_platformer.jpg",
    link: {
      type: "youtube",
      link: "qZgvqRzoY4Q",
    },
  },
  awakening: {
    name: "Awakening",
    description: "Scoring contest submission for the Native Instruments “Score with Orchestral Tools” competition.",
    type: "Music & SFX",
    img: "/img/reel/awakening.jpg",
    link: {
      type: "youtube",
      link: "doTfYoDAUnQ",
    },
  },
  flower: {
    name: "Flower",
    description: "Edited gameplay re-score of thatgamecompany's beautifully poetic game 'Flower'.",
    type: "Music",
    img: "/img/reel/flower.jpg",
    link: {
      type: "youtube",
      link: "oCtumFAQJY4",
    },
  },
  a_good_boy: {
    name: "A good boy",
    description: "Music done for a <a href='https://bluedrak3.itch.io/a-good-boy'>cute little game jam game</a> about a dog trying to find the round object that the owner threw. ",
    type: "Music",
    img: "/img/reel/a_good_boy.jpg",
    link: {
      type: "soundcloud",
      link: "https://soundcloud.com/bluedrak3/a-good-boy/s-LUQbGSagjSw",
      trackId: "967865272",
      secret: "s-LUQbGSagjSw",
    },
  },
  run_with_the_dragons: {
    name: "Run with the Dragons",
    description: "Original piece created based on the artwork of one of my personal favourite artists, <a href='https://twitter.com/kaneblob'>Kane</a>.",
    type: "Music",
    img: "/img/reel/run_with_the_dragons.jpg",
    link: {
      type: "soundcloud",
      link: "https://soundcloud.com/bluedrak3/run-with-the-dragons",
      trackId: "574495872",
    },
  },
  know_thyself: {
    name: "Know Thyself",
    description: "Cover of an originally written piece by the amazing <a href='https://twitter.com/awintory'>Austin Wintory</a>, who made it a twitter challenge to create a cover of it before the actual announcement of Erica, the videogame it was composed for.",
    type: "Music",
    img: "/img/reel/know_thyself.jpg",
    link: {
      type: "soundcloud",
      link: "https://soundcloud.com/bluedrak3/know-thyself-bluedrak3-cover-1",
      trackId: "669164756",
    },
  },
  opera_gx: {
    name: "Opera GX",
    description: "Submission for the Opera GX scoring contest.",
    type: "Music",
    img: "/img/reel/opera_gx.jpg",
    link: {
      type: "soundcloud",
      link: "https://soundcloud.com/bluedrak3/loop-opera-gx-competition-submission/s-x8Lk8f7hevx",
      trackId: "909145678",
      secret: "s-x8Lk8f7hevx"
    },
  },
}