import React, { useState } from 'react';
import YouTube from 'react-youtube';

import { trySetMute } from './scripts/audio-handler';

function CustomYouTube({ ...args }) {
  const handleVidState = (state) => {
    switch (state.data) {
      case 1: // playing 
      case 3: // buffering
      case 5: // video cued
        trySetMute(true);
        break;
      case 0: // ended
      case 2: // paused         
        trySetMute(false);
        break;
      default:
        break;
    }
  };

  return (
    <YouTube opts={opts} onStateChange={handleVidState} {...args} />
  )
}

const opts = {
  frameborder: "0",
  playerVars: {
    autoplay: 0,
    mute: 0,
    autohide: 1,
    controls: 1,
    playsinline: 1,
    showinfo: 0,
    iv_load_policy: 3,
    rel: 1,
    color: "white",
    modestbranding: 1,
    enablejsapi: 1,
    origin: window.location,
  },
};

export default CustomYouTube
