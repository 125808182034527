import React, { useRef } from 'react';
import { motion, AnimateSharedLayout, AnimatePresence } from 'framer-motion';
import {
  useParams,
  useHistory,
} from "react-router-dom";

import NavButton from './../NavButton';
import { playOneShot } from '../scripts/audio-handler';

import { reel } from './demo-reel-data';
import DetailsPane from './DetailsPane';
import Card from './Card';


function DemoReel() {
  const player = useRef(null);
  let history = useHistory();
  let { pieceId } = useParams();

  function handleClick(release) {
    let s = "/demo-reel" + (release ? ('/' + release) : "");
    playOneShot("click");
    history.push(s);
    player.current?.pause();
  }

  return (
    <motion.section
      id="demo-reel"
      className="reel"
    >
      <motion.div
        className="container"
        initial="initial"
        animate="in"
        exit="out"
        variants={variants}
      >
        <motion.h2>
          Demo reel
        </motion.h2>

        <AnimateSharedLayout>
          <motion.div
            layout
            className="release-card-container" >
            {Object.keys(reel).map(id => (
              <Card
                key={id}
                layoutId={id}
                piece={reel[id]}
                isSelected={pieceId != null && id === pieceId}
                onClick={() => handleClick(id)}

              />
            ))}
          </motion.div>
          <DetailsPane
            releaseId={pieceId}
            ref={player}
            close={() => handleClick(null)}
          />
        </AnimateSharedLayout>

        <motion.div className="button-cont">
          <NavButton link="/"> back </NavButton>
        </motion.div>

      </motion.div>
    </motion.section>
  )
}

export default DemoReel;


const variants = {
  initial: {
    opacity: 0,
    y: 0,
    x: 15,
    transition: {
      duration: 0.5,
    }
  },
  in: {
    opacity: 1,
    y: 0,
    x: 0,
    transition: {
      duration: 0.5,
    }
  },
  out: {
    opacity: 0,
    transition: {
      delay: 0.2,
      duration: 0.5,
    }
  }
};

