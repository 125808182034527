import React, { useState, useEffect, useRef, useCallback } from 'react';
import { motion, AnimateSharedLayout, AnimatePresence } from 'framer-motion';
import {
  useParams,
  useHistory,
} from "react-router-dom";

import NavButton from './../NavButton';
import { playOneShot } from '../scripts/audio-handler';

import { releases } from './releases-data';
import ReleaseDetails from './ReleaseDetails';
import ReleaseCard from './ReleaseCard';


function MusicReleases() {
  const player = useRef(null);
  let history = useHistory();
  let { releaseId } = useParams();

  const handleClick = useCallback(
    (release) => {
      let s = "/releases" + (release ? ('/' + release) : "");
      playOneShot("click");
      history.push(s);
      player.current?.pause();
    },
    []
  );

  return (
    <motion.section
      id="releases"
      className="reel"
    >
      <motion.div
        className="container"
        initial="initial"
        animate="in"
        exit="out"
        variants={variants}
      >
        <motion.h2>
          Music Releases
        </motion.h2>

        <AnimateSharedLayout>
          <ReleaseDetails
            releaseId={releaseId}
            ref={player}
            close={() => handleClick(null)}
          />
          <motion.div
            layout
            className="release-card-container" >
            {Object.keys(releases).map(id => (
              <ReleaseCard
                key={id}
                layoutId={id}
                release={releases[id]}
                isSelected={releaseId != null && id === releaseId}
                onClick={() => handleClick(id)}

              />
            ))}
          </motion.div>
        </AnimateSharedLayout>

        <motion.div className="button-cont">
          <NavButton link="/"> back </NavButton>
        </motion.div>

      </motion.div>
    </motion.section>
  )
}

export default MusicReleases;


const variants = {
  initial: {
    opacity: 0,
    y: 0,
    x: -15,
    transition: {
      duration: 0.5,
    }
  },
  in: {
    opacity: 1,
    y: 0,
    x: 0,
    transition: {
      duration: 0.5,
    }
  },
  out: {
    opacity: 0,
    transition: {
      delay: 0.2,
      duration: 0.5,
    }
  }
};

