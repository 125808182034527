import React from 'react';
import { motion } from 'framer-motion';

import NavButton from './../NavButton';

import { Carousel } from './Carousel';


const variants = {
  initial: {
    opacity: 0,
    y: 15,
    x: 0,
    transition: {
      duration: 0.5,
    }
  },
  in: {
    opacity: 1,
    y: 0,
    x: 0,
    transition: {
      duration: 0.5,
    }
  },
  out: {
    opacity: 0,
    transition: {
      delay: 0.2,
      duration: 0.5,
    }
  }
};

function Commissions() {
  return (
    <motion.section
      id="commissions"
      initial="initial"
      animate="in"
      exit="out"
      variants={variants}
    >
      <div className="container">
        <div className="prices">
          <h2>Commission Prices</h2>
          <p><strong>Vocal mixing</strong></p>
          <div className="table-wrapper">
            <div className="table-inner">
              <table>
                <tbody>
                  <tr>
                    <td>Mix & Master </td>
                    <td>35€ per main vocal track, +5€ for extras (harmonies, adlibs, etc)</td>
                  </tr>
                  <tr>
                    <td>Timing</td>
                    <td>2 - 10€ for each full-length track</td>
                  </tr>
                  <tr>
                    <td>Tuning</td>
                    <td>2 - 10€ for each full-length track</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <p><strong>Production</strong></p>
          <div className="table-wrapper">
            <div className="table-inner">
              <table>
                <tbody>
                  <tr>
                    <td>Original</td>
                    <td>125 - 375€</td>
                  </tr>
                  <tr>
                    <td>Cover</td>
                    <td>100 - 300€</td>
                  </tr>
                  <tr>
                    <td>Remix</td>
                    <td>100 - 300€</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <p>
            <span className="li">price ranges are dependent on task complexity</span>
            <span className="li">all prices are negotiable depending on the project! </span>
            <span className="li">get in touch via email / dm / Discord (BlueDrak3#2250) :D</span>
          </p>

        </div>
        <div className="examples">
          <p><strong>Examples</strong></p>
          <div className="example-container">
            <Carousel />
          </div>
        </div>
        <motion.div className="button-cont" layoutId="navButton">
          <NavButton link="/"> back </NavButton>
        </motion.div>
      </div>


    </motion.section>

  )
}

export default Commissions
