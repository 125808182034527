import React, { useState } from 'react';
import { AnimatePresence } from "framer-motion";
import {
  Switch,
  Route,
  useLocation
} from "react-router-dom";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";

import BgImage from './components/BgImage';
import TitleCard from './components/TitleCard';
import Commissions from './components/Commissions';
import DemoReel from './components/DemoReel';
import MusicReleases from './components/MusicReleases';
import AudioControls from './components/AudioControls';
import CustomScrollbar from './components/CustomScrollbar';
import ParticleBG from './components/ParticleBG';
import OtherLinks from './components/OtherLinks';


function App() {
  const location = useLocation();
  const [clicked, setClicked] = useState(false);
  return (
    <>
      <BrowserView><ParticleBG /></BrowserView>
      <CustomScrollbar style={{ width: "100%", height: "100%" }}>
        <BgImage />
        <AnimatePresence exitBeforeEnter>
          <Switch location={location} key={location.pathname.split('/')[1]}>
            <Route path="/commissions">
              <Commissions />
            </Route>
            <Route path="/demo-reel/:pieceId">
              <DemoReel />
            </Route>
            <Route path="/demo-reel">
              <DemoReel />
            </Route>
            <Route path="/releases/:releaseId">
              <MusicReleases />
            </Route>
            <Route path="/releases">
              <MusicReleases />
            </Route>
            <Route path="/">
              <TitleCard />
            </Route>
          </Switch>
        </AnimatePresence>

        <BrowserView>
          <AudioControls click={setClicked} />
          <OtherLinks clicked={clicked} />
        </BrowserView>
      </CustomScrollbar>

    </>
  );
}

export default App;
