import React, { useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence, useMotionValue, useTransform } from 'framer-motion';
import * as AudioHandler from './scripts/audio-handler';

function Slider() {
  const boundsRef = useRef(null);
  const x = useMotionValue(1);
  const width = useTransform(x, val => ((val / 44 + 1) * 100) + "%");

  useEffect(() => {
    x.onChange(latest => {
      AudioHandler.vol(latest / 44 + 1);
    })
  }, []);

  return (
    <motion.div className="slider-container" ref={boundsRef}>
      <motion.div className="slider-fill" style={{ width }} />
      <motion.div drag="x" style={{ x }} dragElastic={false} dragMomentum={false} dragConstraints={boundsRef} className="knob"></motion.div>
    </motion.div>
  );
}

function AudioControls({ click }) {
  const [activated, setActive] = useState(false);
  const [muted, setMuted] = useState(false);

  function handleFirstClick() {
    AudioHandler.setActive(true);
    AudioHandler.initAudio();
    setActive(true);
    click(true);
    document.removeEventListener("click", handleFirstClick);
  }

  useEffect(() => {
    document.addEventListener("click", handleFirstClick);
    return () => {
      document.removeEventListener("click", handleFirstClick);
    }
  }, []);

  return (
    <AnimatePresence>
      {!activated && <motion.div key="activate" id="audio-controls-activate"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}>
        <span style={{ textDecoration: "none" }}>{"> "}</span>
        <motion.span
          style={{ textDecoration: "none" }}
          animate={{ paddingLeft: [15, 1, 15], paddingRight: [15, 1, 15], transition: { times: [0, 0.2, 1], loop: Infinity, repeatDelay: 1, type: "spring", bounce: 1 } }}
        >
          click anywhere to activate background audio & sfx
        </motion.span>
        <span style={{ textDecoration: "none" }}>{" <"}</span>
      </motion.div>}

      {activated && <motion.div key="audio-control" id="audio-controls"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}>
        <button className={muted ? "muted" : ""} onClick={() => {
          AudioHandler.toggleMute();
          setMuted(!muted);
        }} id="mute">mute</button>
        <Slider />
      </motion.div>}
    </AnimatePresence>
  )
}

export default AudioControls;