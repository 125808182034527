export const releases = {
  escape: {
    name: "Escape (ft. Moon Jelly)",
    img: "/img/releases/escape.png",
    description: 'melodic drum and bass released on UKF\'s sublabel - Pilot!',
    mainLink: "6I2_ZWWO-Vs",
    secondaryLinks: [
      { type: "soundcloud", link: "https://soundcloud.com/pilotrecordsuk/hyukari-escape?in=pilotrecordsuk/sets/destinations-vol-2" },
      { type: "spotify", link: "https://open.spotify.com/embed/album/51Xt92zHROkA07mDwdWDoC" },
    ],
    otherLinks: ""
  },
  your_touch: {
    name: "Your Touch (& The Arcturians)",
    img: "/img/releases/your_touch.jpg",
    description: "darker melodic drum and bass released on RAM Records.",
    mainLink: "PLkMz58bxVQ",
    secondaryLinks: [{ type: "spotify", link: "https://open.spotify.com/embed/track/3hyvu98HQUpkgM1N1k77gc" },],
    otherLinks: ""
  },
  find_a_release: {
    name: "Find a Release (& The Arcturians)",
    img: "/img/releases/find_a_release.jpg",
    description: "melodic dubstep with a hint of trance released on Circus Records.",
    mainLink: "x_UiUYJHasA",
    secondaryLinks: [{ type: "spotify", link: "https://open.spotify.com/embed/track/4KFR3yYQNoBXz0CXbA5DC9" },],
    otherLinks: ""
  },
  hollow_knight: {
    name: "Hollow Knight (Remix)",
    img: "/img/releases/hollow_knight.jpg",
    description: "a remix of the main theme from the soundtrack of one of my favourite games - Hollow Knight!",
    mainLink: "hpMFlXH8Rp0",
    secondaryLinks: [],
    otherLinks: ""
  },
  sugar_coat: {
    name: "Sugar Coat",
    img: "/img/releases/sugar_coat.jpg",
    description: "cute melodic midtempo released on Wolf Beats",
    mainLink: "faq2zyU9ym0",
    secondaryLinks: [{ type: "spotify", link: "https://open.spotify.com/embed/track/5eDHB0GYIeaVC4HwpGM4iv" },],
    otherLinks: ""
  },
  over_the_horizon: {
    name: "Over the Horizon",
    img: "/img/releases/over_the_horizon.jpg",
    description: "lo-fi-ish / vaporwavey melodic midtempo released on Tri Records",
    mainLink: "TMnhV9TDLl0",
    secondaryLinks: [{ type: "spotify", link: "https://open.spotify.com/embed/track/5xykvhfhCtyJM2d8bCW0et" },],
    otherLinks: ""
  },
  ichor: {
    name: "Ichor",
    img: "/img/releases/ichor.jpg",
    description: "bassy house with a hint of trance released on Tri Records",
    mainLink: "Zzuv4H4Atl4",
    secondaryLinks: [{ type: "spotify", link: "https://open.spotify.com/embed/album/5fZqgoK1moUxkuKNdA1gjV?si=4AeXQK2RTWKDYkp86HsfDQ" },],
    otherLinks: ""
  },
};