import React, { Component } from 'react';
import { trySetMute } from './scripts/audio-handler';

export default class CustomSoundcloud extends Component {
  constructor(props) {
    super(props);
    this.widget = null;
    this.setupWidget = this.setupWidget.bind(this);
    this.muteBG = this.muteBG.bind(this);
    this.pause = this.pause.bind(this);
  }

  setupWidget() {
    this.widget = window.SC.Widget(document.getElementById('sc-iframe'));
    this.widget.bind(window.SC.Widget.Events.PLAY, () => this.muteBG(true));
    this.widget.bind(window.SC.Widget.Events.PAUSE, () => this.muteBG(false));
    this.widget.bind(window.SC.Widget.Events.FINISH, () => this.muteBG(false));
  }

  pause() {
    this.widget.pause();
  }

  muteBG(b) {
    trySetMute(b);
  }

  componentDidMount() {
    let script = document.getElementById('sc-script');
    if (!script) {
      script = document.createElement('script');
      script.id = 'sc-script';
      script.src = '/sc-api.js';
      script.async = true;
      script.onload = () => this.setupWidget();
      document.body.appendChild(script);
    } else {
      this.setupWidget();
    }
  }

  componentWillUnmount() {
    document.getElementById('sc-script').remove();
  }

  render() {
    const { link, ...rest } = this.props;
    const secret = link.secret ? "%3Fsecret_token%3D" + link.secret : "";
    return (
      <iframe
        id="sc-iframe"
        width="640"
        height="380"
        scrolling="no"
        frameBorder="no"
        allow="autoplay"
        src={"https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/" +
          link.trackId +
          secret +
          "&color=%239da6ba&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"}>
      </iframe>
    )
  }
}