import React, { useState, useRef, useEffect } from 'react';
import { motion } from 'framer-motion';

const variants = {
  initial: {
    scale: 1
  },
  hover: {
    scale: 1.1
  }
}

function ReleaseCard({ release, isSelected, ...other }) {
  console.log(release.name);
  return (
    <motion.div
      key={release.name}
      className="release-card"
      variants={variants}
      animate={"initial"}
      {...other}
      layout
    >
      <motion.div className="scaler">
        <motion.img layout key={"cover"} src={release.img} alt={release.name + " cover art"} />
        <motion.div layout key={"name"} >
          {release.name}
        </motion.div>
        {isSelected && (
          <motion.div
            layout
            key="outline"
            layoutId="outline"
            className="outline"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ type: "spring", stiffness: 500, damping: 40 }}
          />
        )}
      </motion.div>

    </motion.div>
  );
}

export default ReleaseCard
