import React from 'react';

import CustomYouTube from './CustomYouTube';
import { motion } from 'framer-motion';

class SmoothYoutube extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      player: null,
    }
    this.handleLoaded = this.handleLoaded.bind(this);
  }

  handleLoaded(event) {
    this.state.player = (event.target);
    this.props.onLoad?.(true);
  }

  pause() {
    this.state.player?.pauseVideo();
  }

  variants = {
    unloaded: { opacity: 0 },
    loaded: { opacity: 1 }
  }

  render() {
    const { loaded, onload, ...args } = this.props;
    return (
      <motion.div variants={this.variants} initial="unloaded" animate={loaded ? "loaded" : "unloaded"} opts={opts} className="vid-parent-container">
        <CustomYouTube {...args} onReady={this.handleLoaded} />
      </motion.div>
    );
  }
}

const opts = {
  frameborder: "0",
  playerVars: {
    autoplay: 0,
    mute: 0,
    autohide: 1,
    controls: 1,
    playsinline: 1,
    showinfo: 0,
    iv_load_policy: 3,
    rel: 0,
    color: "white",
    modestbranding: 1,
    enablejsapi: 1,
  },
};

export default SmoothYoutube
