import { Howl, Howler } from 'howler';

let muted = false;
let volume = 1;
let active = false;

function vol(vol) {
  Howler.volume(vol);
  volume = vol;
}

function setActive(b) {
  active = b;
}

function toggleMute() {
  if (muted) {
    Howler.mute(!muted);
    bg.fade(0, 1, 2000);
  } else {
    Howler.mute(true);
  }
  muted = !muted;
  //Howler.mute(muted);
}

function trySetMute(b) {
  if (!b && muted) return;
  if (!b) {
    Howler.mute(b);
    bg.fade(0, 1, 2000);
  } else {
    Howler.mute(true)
  }
}

function mute(b) {
  Howler.mute(b);
}

function volPlus() {
  if (volume <= 0.8) volume += 0.2;
  Howler.volume(volume);
}

function volMinus() {
  if (volume >= 0.2) volume -= 0.2;
  Howler.volume(volume);
}

const bg = new Howl({
  src: ["/audio/atmo.webm"],
  loop: true,
  onplayerror: function () {
    bg.once('unlock', function () {
      bg.play();
    });
  },
});

const fadeHigh = new Howl({
  src: ["/audio/- fade high.ogg"],
  volume: 1
});

const fadeHighRev = new Howl({
  src: ["/audio/- fade high_rev.ogg"],
  volume: 0.8
});

const fadeLow = new Howl({
  src: ["/audio/- fade low_1.ogg"],
  volume: 0.7
});

const fadeLowRev = new Howl({
  src: ["/audio/- fade low_1_rev.ogg"],
  volume: 0.4
});

const bleep1 = new Howl({
  src: ["/audio/- bleep 1.ogg"],
  volume: 1.8
});
const bleep2 = new Howl({
  src: ["/audio/- bleep 2.ogg"],
  volume: 1.8
});
const click = new Howl({
  src: ["/audio/click.ogg"],
  volume: 0.7
});

const fill1 = new Howl({
  src: ["/audio/- fill 1.ogg"],
  volume: 0.7
});
const fill2 = new Howl({
  src: ["/audio/- fill 2.ogg"],
  volume: 0.7
});
const fill3 = new Howl({
  src: ["/audio/- fill 3.ogg"],
  volume: 0.7
});

const fills = [fill1, fill2, fill3];

let roundrobinBeep = true;
function playBleep() {
  if (roundrobinBeep) bleep1.play();
  else bleep2.play();
  roundrobinBeep = !roundrobinBeep;
}

export function playOneShot(name) {
  switch (name) {
    case "fadeHigh":
      if (!active) return;
      fadeHigh.play();
      break;
    case "fadeHighRev":
      if (!active) return;
      fadeHighRev.play();
      break;
    case "fadeLow":
      if (!active) return;
      fadeLow.play();
      break;
    case "fadeLowRev":
      if (!active) return;
      fadeLowRev.play();
      break;
    case "click":
      click.play();
      break;
    case "bleep":
    default:
      playBleep();
      break;
  }
}

let curFill = Math.floor(Math.random() * 3);
function playFillRR() {
  fills[curFill].play();
  fills[curFill].once("end", playDelayed);
  curFill = (curFill + Math.floor(Math.random() * 2) + 1) % 3;
}

function playDelayed() {
  setTimeout(function () {
    playFillRR();
  }, Math.random() * 10000);
}

function initAudio() {
  bg.volume(0);
  bg.play();
  bg.fade(0, 0.8, 2000);
  playDelayed();
}

export { initAudio, mute, volMinus, volPlus, vol, toggleMute, trySetMute, setActive };
