import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { wrap } from "popmotion";

import CustomYouTube from './../CustomYouTube';
import { playOneShot } from './../scripts/audio-handler';

import { references } from "./carousel-yt-data";

const variants = {
  enter: (direction) => {
    return {
      x: direction > 0 ? 300 : -300,
      opacity: 0
    };
  },
  center: {
    x: 0,
    opacity: 1
  },
  exit: (direction) => {
    return {
      x: direction < 0 ? 300 : -300,
      opacity: 0
    };
  }
};

/**
 * Experimenting with distilling swipe offset and velocity into a single variable, so the
 * less distance a user has swiped, the more velocity they need to register as a swipe.
 * Should accomodate longer swipes and short flicks without having binary checks on
 * just distance thresholds and velocity > 0.
 */
const swipeConfidenceThreshold = 10000;
const swipePower = (offset, velocity) => {
  return Math.abs(offset) * velocity;
};

export const Carousel = () => {
  const [[page, direction], setPage] = useState([0, 0]);
  const [curPlayer, setCurPlayer] = useState(null);

  // We only have 3 images, but we paginate them absolutely (ie 1, 2, 3, 4, 5...) and
  // then wrap that within 0-2 to find our image ID in the array below. By passing an
  // absolute page index as the `motion` component's `key` prop, `AnimatePresence` will
  // detect it as an entirely new image. So you can infinitely paginate as few as 1 images.
  const imageIndex = wrap(0, references.length, page);

  const paginate = (newDirection) => {
    curPlayer?.pauseVideo();
    setPage([page + newDirection, newDirection]);
  };

  const handleReady = (event) => {
    setCurPlayer(event.target);
  };

  return (
    <>
      <AnimatePresence initial={false} custom={direction}>
        <motion.div
          className="vid-parent-container"
          key={page}
          custom={direction}
          variants={variants}
          initial="enter"
          animate="center"
          exit="exit"
          transition={{
            x: { type: "spring", stiffness: 150, damping: 20, mass: 0.5 },
            opacity: { duration: 0.2, ease: "easeInOut" }
          }}
        >
          <CustomYouTube videoId={references[imageIndex][0]} onReady={handleReady} />
        </motion.div>
        <motion.div
          className="credits"
          key={"_" + page}
          custom={direction}
          variants={variants}
          initial="enter"
          animate="center"
          exit="exit"
          transition={{
            x: { type: "spring", stiffness: 150, damping: 20, mass: 0.5 },
            opacity: { duration: 0.2, ease: "easeInOut" }
          }}
        >
          <span>[{references[imageIndex][1]}]</span>
        </motion.div>
      </AnimatePresence>
      <PaginateButton onClick={() => paginate(1)} className="next" />
      <PaginateButton onClick={() => paginate(-1)} className="prev" />
    </>
  );
};

function PaginateButton({ onClick, className }) {
  return (
    <div
      className={className + " nav-btn"}
      onClick={() => {
        onClick?.();
        playOneShot("click");
      }}
      onMouseOver={() => playOneShot("fadeLow")}
      onMouseLeave={() => playOneShot("fadeLowRev")}>
      {"‣"}
    </div>
  );
}

