import React from 'react';
import Particles from 'react-particles-js';
import data from './particlesjs-config.json';
import { motion } from 'framer-motion'

function ParticleBG() {
  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 6, type: 'ease', ease: 'easeInOut' }}>
      <Particles style={{ mixBlendMode: "difference" }} params={data} />
      <Particles style={{ filter: "blur(5px)" }} params={data} />
    </motion.div>
  )
}

export default ParticleBG
